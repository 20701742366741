import React, { Fragment } from 'react';

const completeTimeline = ["0", "1", "42", "46", "73", "82", "90", "103", "112", "110", "113", "127", "143", "149", "156", "157", "171", "162", "184", "256", "270", "275", "284", "313", "315", "319", "322", "323", "330", "339", "341", "348", "356", "372", "381", "402", "403", "411", "458", "467", "474", "475", "480", "482", "510", "513", "528", "529", "571", "574", "580", "583", "585", "586", "598", "599", "601", "608", "611", "620", "623", "626", "628", "635", "636", "639", "640", "642", "643", "648", "649", "652", "653"];

const timeLineEventsByRace = [
    {  
        race: 'Enanos',
        color: '#7d1111',
        offset: '0',
        events: {
           "0": {
                location: "Darr-Kar",
                zoom: 1,
                info: <p><span>J</span>ormar se aparece a su raza y advierte del comienzo de la Kuthak-Tak, como es conocida entre los enanos la Primera Gran Guerra.</p>,
                title: "La aparición de un dios",
                text: <Fragment> <p> <span>E</span>l poderoso Jormar se aparece en el gran salón de Darr-Kar, ante la atónita mirada de los allí presentes. </p> 
                <p> <span>C</span>on gesto amable pero sombrío, se dirige al consejo regente y avisa de la llegada de tiempos duros y tristes para los enanos. El dios forjador aconseja a sus hijos que escojan los mejores de entre los suyos para que reunan a los clanes bajo su mando. Bolgrim Barbahueso, Torkhan Barbablanca, Maeras Forjamartillo y Frithnir Hachafuego son elegidos para liderar a su gente y defender el hogar del pueblo enano. </p> </Fragment>},
           "1": {
                location: [3385, 1026],
                zoom: 1,
                info: <p><span>S</span>e da por comenzada la Primera Gran Guerra y los enanos se refugian en el interior de las montañas. Los grandes ejércitos del pueblo enano vigilan los pasos de montaña y defienden las fortalezas.</p>},
           "82": {
                location: [3588, 1181],
                zoom: 1,
                info: <p><span>B</span>olgrim Barbahueso es derrotado por el jefe de guerra orco Gurrak Puñogrís. El ejército que lideraba sufre el mismo final que su señor al ser arrojados por un desfiladero al sur del Bosque de Roc. Unos meses más tarde, Maeras y Frithnir dan caza a la fuerza pielverde y acaban con el señor de la guerra.</p>},
           "113": {
                location: "Har-Kar",
                zoom: 1,
                info: <p><span>E</span>l dragón Hildurr, la Muerte Blanca, dirige un ejército que avanza desde el norte. En las puertas de Har-kar tiene lugar una descomunal batalla donde Hildurr es derrotado y huye.</p>,
                title: "La Batalla del Aliento de hielo",
                text: <Fragment> <p><span>L</span>a fuerza enana comandada por Frithnir y Maeras recorre los caminos subterráneos bajo Har-Kar para sorprender al ejército pielverde concentrado en las puertas de la fortaleza. </p>
                <p><span>E</span>l colosal dragón blanco Hildurr exige la rendición de la urbe y la pleitesía de los enanos mientras otros dos dragones blancos de menor tamaño castigan las defensas con la ventisca de su aliento.</p> 
                <p><span>C</span>ada uno de los líderes enanos se enfrenta a uno de los dragones blancos, acabando con ellos y desmoralizando al ejército agresor. Hildurr huye entre juramentos de venganza que son apagados por los vítores enanos. </p> </Fragment>},
            "127": {
                location: [3457, 979],
                zoom: 1,
                info: <p><span>H</span>elga Filohacha, señora de Izul-Gar, dirige varias cacerías de dragones en las Crestadragón junto a su clan. Durante siglos enseñará el oficio a los suyos.</p>},
            "143": {
                location: "Zar-Nabrand",
                zoom: 1,
                info: <p><span>L</span>os gigantes de Loragog atacan las defensas exteriores de Zar-Nabrand. Una fuerza de jinetes humanos enviada por los Manantiales hostiga a los gigantes hasta hacerlos abandonar el asedio.</p>},
            "156": {
                location: "Darr-Kar",
                zoom: 1,
                info: <p><span>C</span>on la rendición de Tulagak se da por terminada la Kuthak-Tak. Frithnir y Maeras contraen matrimonio en el templo de Bera en Darr-kar.</p>,
                title: "El reinado de los Vencedragones",
                text: <Fragment> <p><span>T</span>ras más de cien años de constante lucha por liberar las montañas del yugo de dragones, gigantes, pielesverdes y otros seres, Frithnir y Maeras son coronados como Krun-Krunaki, Grandes Reyes.</p> 
                <p><span>L</span>os enanos comienzan unas celebraciones que se extenderán durante varios días. En honor a sus gestas, los reyes reciben el título de Vencedragones. </p> 
                <p><span>N</span>o conforme con este nombramiento, Torkhan Barbablanca abandona el salón en plenas celebraciones y desaparece de las crónicas enanas. </p> </Fragment>},
            "162": {
                location: [3729, 1156],
                zoom: 1,
                info: <p><span>S</span>e comienza a reconstruir la fortaleza de Ner-rak-Kar, Altarroca. Los siguientes años serán de una enorme prosperidad. Bruntor, Grum, Arta y Rorek nacen al poco de terminar la guerra.</p>},
            "275": {
                location: "Darr-Kar",
                zoom: 1,
                info: <p><span>C</span>ada uno de los descendientes de Frithnir recibe una de las grandes fortalezas de Fregir para que sean custodiadas por sus herederos y sus familias. El más joven, Rorek, muere expandiendo los dominios de Ner-rak-Kar. Altarroca concluye sus reparaciones en su honor poco tiempo después.</p>},
            "284": {
                location: "Darr-Kar",
                zoom: 1,
                info: <p><span>G</span>rum contrae matrimonio con Rega Filohacha, descendiente de la gran Cazadora de Dragones de Izul-Gar y Frithnir le declara heredero directo de su cargo, pese a no ser el hijo mayor. Grum asume la responsabilidad con el apoyo de su hermana Arta y bajo la torva aprobación de su hermano mayor Bruntor.</p>},
            "315": {
                location: [3406, 989],
                zoom: 1,
                info: <p><span>F</span>rithnir muere en combate mientras atraviesa un paso de montaña con parte del clan real. Una fuerza de gigantes embosca la caravana pero Grum y Arta, allí presentes, protegen el cadáver de su padre hasta hacer retroceder a todos los atacantes. Maeras jura venganza y lidera un ataque sobre la fortaleza gigante de Tulagak. La Gran Reina muere durante el asedio, no sin antes decapitar al rey de los gigantes Logog Puñomontaña.</p>},
            "381": {
                location: "Darr-Kar",
                zoom: 1,
                info: <p><span>N</span>acen Kagrein y Thorj en diferentes meses de este año. Los enanos aseguran así su línea hereditaria.</p>}, 
            "474": {
                location: "Darr-Kar",
                zoom: 1,
                info: <p><span>J</span>ormar vuelve a aparecerse a los enanos, esta vez se manifiesta en un sueño a Grum y le previene acerca de la Segunda Gran Guerra. Como más tarde se dijo, también le advierte de su aciago destino.</p>},
            "475": {
                location: [3406, 989],
                zoom: 1,
                info:<p><span>C</span>on la caída de Tulagak en manos de los pielesverdes estalla la Kuthak-Gur, la Segunda Gran Guerra. Grum, Rega, Bruntor, Arta, Kagrein y Thorj comandan las tropas enanas. Los hijos de Frithnir encuentran un inesperado aliado en los humanos del clan del Halcón.</p>},
            "510": {
                location: [3358, 1060],
                zoom: 1,
                info:<p><span>C</span>omo ya predijo Jormar, Grum muere luchando contra un enorme dragón rojo que abandona el campo de batalla cuando Kagrein acude a proteger a su padre.</p>,
                title: "La Muerte Roja",
                text: <Fragment> <p><span>D</span>escendiendo desde los cielos como un cometa, la gigantesca figura de un dragón rojo atraviesa el firmamento con sus ojos fijos en el rey enano.</p> 
                <p><span>G</span>rum, aceptando su destino, planta cara al ser como hiciera su padre siglos atrás ante las depredaciones de los dragones. La lucha es brutal, pero el hijo de Frithnir no puede superar al temible dragón y cae. </p> 
                <p><span>E</span>s un día triste para la enanidad y para Maëron. </p> </Fragment>},
            "529":  {
                location: [3189, 1571],
                zoom: 1,
                info:<p><span>T</span>ras la muerte de Grum, Kagrein, heredando de su padre el poder sobre su pueblo, lleva a cabo un pacto de hermandad con el líder de los humanos, Meldor hijo de Dorthion, del clan del Halcón.</p>,
                title: "La Alianza",
                text: <Fragment> <p><span>R</span>eunidos en las llanuras del valle del Lerindrol, los enanos y los humanos atrapan entre dos frentes a un poderoso ejército pielverde. </p> 
                <p><span>T</span>ras derrotar a los orcos, los dirigentes de ambas fuerzas entablan una amistad que los enanos sellan con un barril de su mejor aguardiente. A cambio, el líder humano hace entrega al señor enano de un hermoso halcón de las llanuras y el pacto de hermandad queda sellado. </p> </Fragment>},
            "583": {
                location: [3189, 1571],
                zoom: 1,
                info:<p><span>C</span>on la retirada de las últimas fuerzas pielesverdes se da por terminada la guerra. Las fuerzas élficas que han apoyado la lucha se unen a las celebraciones.</p>},
            "586": {
                location: "Darr-Kar",
                zoom: 1,
                info:<p><span>N</span>ace Björn, príncipe de Darr-Kar.</p>},
            "623": {
                location: "Zar-Nabrand",
                zoom: 1,
                info:<p><span>E</span>l príncipe Faerhim, futuro heredero de Zar-Nabrand, da muerte al inmenso gigante Turandar, que lidera una fuerza culpable de la destrucción de varios Nur-Kar.</p>},
            "626": {
                location: "Darr-Kar",
                zoom: 1,
                info:<p><span>D</span>regar, hermano del rey Kagrein, parte junto a sus mejores soldados hacia las montañas del norte para recibir información de los Nur-Kar más septentrionales y del reino de Har-Kar, incomunicado desde hace décadas.</p>},
            "635": {
                location: "Har-Kar",
                zoom: 1,
                info:<p><span>T</span>ras su desaparición y varios años de búsqueda, se encuentran los restos de Dregar y su escolta en unas cuevas cercanas a Har-Kar. La corte se preocupa y Kagrein jura vengar la muerte de su hermano.</p>},
            "636": {
                location: [3406, 989],
                zoom: 0.75,
                info:<p><span>S</span>urgiendo de la nada, los orcos atacan acompañados por todo tipo de criaturas. Incapaces de dividir sus tropas, los enanos se centran en defender las montañas. Parece que una nueva Gran Guerra se avecina, pero tras las primeras escaramuzas los pielesverdes se retiran.</p>},
            "649": {
                location: [3358, 1060],
                zoom: 1,
                info:<p><span>C</span>ontinúa la labor de reconstrucción del imperio enano. Tras siglos de guerras devastadoras las fortalezas más pequeñas han quedado prácticamente destruidas y las capitales de cada reino están severamente dañadas.</p>},
            "652": {
                location: "Darr-Kar",
                zoom: 1,
                info:<p><span>C</span>omo antaño, Jormar se aparece en el salón del rey Kagrein y alerta a los enanos de que la guerra está próxima. Björn asume el papel de su padre bajo petición del dios.</p>},
            "653": {
                location: "Darr-Kar",
                zoom: 0.75,
                info:<p><span>T</span>erremotos catastróficos impiden la salida de los ejércitos de Darr-Kar. Para frustración de Björn, se hace necesario despejar los accesos derrumbados y reconstruir las carreteras para poder marchar hacia el exterior. A Zar-Nabrand llegan noticias de portales infernales por todo Maëron, los demás asentamientos parecen desconocer qué está ocurriendo. Har-Kar continúa incomunicada.</p>},
        }
    },
    {
        race: 'Elfos',
        color: '#297d5d',
        offset: '67.48px',
        events: {
            "0": {
                location: "Naerlan",
                zoom: 1,
                info: <p><span>G</span>uelannaer es elegido rey de Then-Alarion por sus dioses en una celebración ritual en el bosque de álamos. Según algunos cronistas se trata del quincuagéismo primer rey del bosque.</p>},
            "1": {
                location: [1508, 1776],
                zoom: 0.45,
                info: <p><span>D</span>a comienzo la Primera Gran Guerra. Los elfos se enfrentan a su mayor desafío en siglos.</p>},
            "46": {
                location: "Doran-Malanor",
                zoom: 1,
                info: <p><span>E</span>l noble Kelathar de Doran-Malanor se opone al mandato de Guelannaer. El rey se enfrenta al disidente que termina revelándose como el dragón negro Kalksur.</p>,
                title: "Oscura traición",
                text: <Fragment> <p><span>D</span>urante varios años el regente del Bosque del Cazador se aparta de las políticas del rey Guelannaer.</p> 
                <p><span>L</span>a gota que colma la paciencia del señor de Then-Alarion es el deasfío que en plena Gran Guerra supone que el señor de Doran-Malanor se niegue a aportar guerreros y a proteger los accesos a través de la Tamuldrol-Carsaen. </p> 
                <p><span>L</span>a comandante Lunain Flechardiente conduce al rey y a su escolta a través del Bosque del Cazador. Ya en la capital, el dragón negro Kalksur abandona su disfraz y ataca a Guelannaer.</p> 
                <p><span>E</span>l dragón es derrotado pero consigue huir. </p></Fragment>},
             "73": {
                 location: "Doran-Malanor",
                 zoom: 1,
                 info: <p><span>L</span>unain Flechardiente conquista el derecho a gobernar el Bosque del Cazador tras derrotar en combate ritual a su rival Felnnor Tilanar. Acto seguido reinstaura el gobierno comunal de la antigüedad.</p>}, 
            "112": {
                 location: [1508, 1776],
                 zoom: 1,
                 info: <p><span>G</span>ran número de gigantes y pielesverdes surgen por sorpresa de la Salar-Malanor y atacan el desfiladero. Son repelidos a duras penas por las fuerzas de la señora de la guerra Tariel, la Garra de Fain-Salar. En el trascurso de la batalla se descubren ruinas milenarias bajo los pasos del desfiladero.</p>},   
            "157": {
                location: "Naerlan",
                zoom: 1,
                info: <p><span>T</span>ermina la guerra. Los elfos respiran con tranquilidad pese a sus graves pérdidas. Guelannaer y Lunain contraen matrimonio y tienen a su primer hijo, Soronnaer.</p>},
            "171": {
                location: "Naerlan",
                zoom: 1,
                info:<p><span>N</span>ace Alnear, los elfos celebran años de abundancia.</p>},
            "184": {
                location: "Naerlan",
                zoom: 1,
                info:<p><span>N</span>ace Lundariel bajo los favorables augurios del oráculo de Duanna.</p>},
            "256": {
                location: "Dor-Neralk",
                zoom: 1,
                info: <p><span>G</span>uelannaer dirige por última vez a los elfos en batalla y comienza a delegar en su hijo mayor, Soronnaer.</p>,
                title: "Hogar de dragones",
                text: <Fragment><p><span>E</span>l anciano rey lidera su gran ejército atravesando medio continente tras saber que un dragón rojo se ha adentrado en el antiguo bastión de Dor-Neralk, profanando las tumbas de varios dragones de metal. De entre sus hijos, tan solo Soronnaer lo acompaña. </p> 
                <p><span>E</span>l dragón rojo Xardar emplea toda su furia en defender su recién adquirido dominio. Tras verse superado prefiere inmolarse y destruir gran parte de la fortaleza a admitir su derrota. El rey y su hijo logran sobrevivir, así como varios miembros de la guardia real, pero la victoria es amarga y dolorosa. </p> </Fragment>},
            "313":  {
                location: "Naerlan",
                zoom: 1,
                info:<p><span>T</span>ras una larga vida, fallece el gran rey Guelannaer. Su hijo mayor, Soronnaer, accede al trono.</p>},
            "319": {
                location: "Doranhuthiennorel",
                info:<p><span>L</span>a gran diosa Anduraeil ofrece una visión a la dama Alnear, una ventisca repleta de sombras atacando el continente. Una vez advertido Soronnaer del peligro, el rey le pide a su hermana que proteja Athon-Lerin en su nombre.</p>},
            "323": {
                location: [1508, 2067],
                zoom: 1,
                info:<p><span>B</span>uscando reunir y fortalecer al pueblo élfico Lundariel, hermano de Soronnaer, encabeza una cruzada hacia el Reino de los Pinares, Then-Faunnaer, y lucha contra pielesverdes y contra criaturas salvajes.</p>},
            "330": {
                location: [1508, 2067],
                zoom: 1,
                info:<p><span>T</span>hen-Faunnaer es arrasado por incendios imparables. Lundariel nombra al nuevo reino Then-Ulcanner, Reino de las Cenizas.</p>},
            "339": {
                location: [1520, 1104],
                zoom: 1,
                info:<p><span>S</span>oronnaer conoce por accidente a la dragona plateada Aliniar. Sus destinos quedan entrelazados.</p>},
            "348": {
                location: [1520, 1104],
                zoom: 1,
                info:<p><span>S</span>oronnaer combate junto a la dragona de plata Aliniar contra el rojo Kaladrak.</p>,
                title: "La Batalla de la Alameda",
                text: <Fragment> <p><span>C</span>uando Soronnaer da alcance a los dos dragones, estos luchan encarnizadamente sobre los árboles del Then-Alarion. Ambas criaturas vuelan esquivando las ramas más afiladas y los troncos más gruesos, con dispar fortuna ya que Aliniar cae derribada. </p>
                <p><span>E</span>l rey de los elfos galopa tras ellos hasta que el cansancio puede con los colosos, que descienden a tierra para un último enfrentamiento. </p>
                <p><span>A</span>liniar se encuentra herida de gravedad por el fuego del dragón rojo Kaladrak y le cuesta mantenerse en pie. Soronnaer releva a su compañera y carga contra el Alas de Fuego. Tras varias horas de lucha, el dragón rojo abandona la liza malherido y Soronnaer puede atender a la dragona plateada y llevarla ante los druidas y clérigos de Then-Alarion para que atendiendan sus heridas. </p> </Fragment>},
            "356": {
                location: "Naerlan",
                zoom: 1,
                info:<p><span>S</span>oronnaer y Aliniar contraen matrimonio ante las muestras de respeto de los demás dirigentes élficos.</p>},
            "372": {
                location: "Naerlan",
                zoom: 1,
                info:<p><span>A</span>liniar muere tras dar a luz un varón, el heredero de Soronnaer, Melianor.</p>},
            "402": {
                location: [1084, 2313],
                zoom: 1,
                info:<p><span>S</span>oronnaer desaparece en el desierto de ceniza y no es vuelto a ver.</p>,
                title: "La sombra de un rey",
                text: <Fragment> <p><span>T</span>ras aprovisionarse escasamente, Soronnaer parte hacia el sur del continente en total silencio.</p>
                <p><span>D</span>urante meses atraviesa los dominios de los distintos señores élficos sin mediar palabra. Rara vez hace un alto en el camino y los elfos empiezan a creer que se trata de un fantasma y no del auténtico rey. </p>
                <p><span>E</span>n las fronteras de Then-Faunnaer el propio Lundariel ordena dejar pasar a su hermano mientras él mismo lo acompaña a través de sus tierras. En la frontera más al sur del reino de los pinares, Lundariel se despide de su hermano mientras contempla como se pierde en las polvorientas dunas del desierto de ceniza para siempre. </p> </Fragment>},
            "403": {
                location: "Thenariel",
                zoom: 1,
                info:<p><span>M</span>elianor accede al trono a pesar de su corta edad.</p>},
            "475": {
                location: "Dor-Rheuin",
                zoom: 1,
                info:<p><span>D</span>a comienzo la Segunda Gran Guerra, los bosques se convierten en un hervidero de enemigos.</p>, 
                title: "Una petición de auxilio",
                text: <Fragment><p><span>E</span>l puerto de Dor-Rehuin recibe un velero destartalado de factura humana cuyos ocupantes están muertos o deliran por hambre o deshidratación. </p>
                <p><span>T</span>ras varios días luchando por su vida, uno de los marineros consigue recuperar el conocimiento el tiempo suficiente para informar a sus salvadores del mensaje de auxilio que el rey humano Dorthion ha enviado desde las llanuras de Fregir. </p>
                <p><span>A</span> pesar de estar sumidos en la lucha contra los pielesverdes y contra las bestias que surgen de los bosques, los elfos envían pequeños contingentes de exploradores al continente vecino. </p> </Fragment>},
            "580": {
                location: "Thenariel",
                zoom: 1,
                info:<p><span>N</span>ace Lernnaer, hijo de Melianor y Laernar. Viendo próxima la victoria élfica en los bosques de Sarler, el rey de los elfos parte hacia Fregir.</p>},
            "583": {
                location: [3189, 1571],
                zoom: 1,
                info:<p><span>T</span>ermina la Segunda Gran Guerra. Melianor permanece en Fregir varios meses antes de regresar a su reino, supervisando la reconstrucción de Drenan.</p>},
            "639": {
                location: "Doranhuthiennorel",
                zoom: 1,
                info:<p><span>L</span>a venerable anciana Alnear de Athon-Lerin cae en un trance místico que dura semanas. Al despertar ordena avisar al rey de que una gran calamidad se avecina.</p>},
            "640": {
                location: "Dor-Rheuin",
                zoom: 1,
                info:<p><span>T</span>ras recibir un mensaje del rey humano Kelirion, el príncipe Lernnaer comanda sus tropas hacia el continente de Fregir.</p>},
            "643": {
                location: "Thenariel",
                zoom: 1,
                info:<p><span>M</span>uere Melianor debido a una enfermedad desconocida e incurable.</p>},
            "648": {
                location: "Thenariel",
                zoom: 1,
                info:<p><span>A</span>nte la retirada de los pielesverdes, las fuerzas élficas regresan a su hogar. Lernnaer es nombrado rey de Then-Alarion y pasa los siguientes años padeciendo las intrigas del continente.</p>},
            "652": {
                location: "Ralnnaer",
                zoom: 1,
                info:<p><span>T</span>ras un corto periodo de paz, los orcos invaden los bosques de ceniza y se pierde el contacto con Then-Ulcanner. El propio Then-Alarion es asediado por criaturas de hueso que amenazan con arrasar el norte del continente.</p>},
            "653": {
                location: [1888,880],
                zoom: 1,
                info:<p><span>L</span>os oráculos deliran y palidecen ante visiones de pesadilla, según los balbuceos de unos pocos la Guerra de las Almas está próxima.</p>},
        }
    },
    {
        race: 'Humanos',
        color: '#0e3069',
        offset: '116.11px',
        events: {
            "1": {
                location: [3643, 1915],
                zoom: 0.75,
                info: <p><span>E</span>stalla la Primera Gran Guerra. El pueblo humano se encuentra disperso en innumerables ciudades independientes, fortalezas familiares y pequeñas poblaciones. Algunos grupos se unen a las fuerzas de la destrucción mientras los demás luchan por sobrevivir.</p>},
            "42": {
                location: [3168, 1606],
                zoom: 1,
                info: <p><span>O</span>lara Lansh, gobernadora de la pequeña ciudad estado de Puertarrío, ofrece auxilio a la vecina Lerindor. Ambas ciudades quedan hermanadas y se prestan ayuda en numerosas ocasiones durante la Primera Gran Guerra.</p>},
            "90": {
                location: [3873, 1699],
                zoom: 1,
                info: <p><span>T</span>ras siglos de autarquía, el líder del clan Kathar de Ymil propone crear una coalición de ciudades humanas bajo su mando para enfrentarse a las depredaciones de la guerra. En el consejo de las Fuentes del Harith la propuesta es rechazada y estalla un conflicto entre los clanes de los Dientes de Sergan y el resto de ciudades libres.</p>},
            "103": {
                location: [3643, 1915],
                zoom: 1,
                info: <p><span>A</span>naer Nashar, señora de Guardia Halcón, es derrotada y esclavizada junto a su pueblo por los Glakhün del Hogar. A los pocos meses ella misma organiza una revuelta y consigue liberar a los suyos del cautiverio, debilitando severamente a los Amos de la Muerte.</p>},
            "110": {
                location: [3566, 1044],
                zoom: 1,
                info: <p><span>L</span>a Reina del Nido, Isara Muhas, lidera una fuerza que consigue ahuyentar al dragón blanco Regnor Viento Invernal de los pasos de montaña cercanos al Bosque de Roc. Los enanos renuevan sus tratados con el Nido.</p>},
            "143": {
                location: "Los Manantiales",
                zoom: 1,
                info: <p><span>L</span>a antigua ciudad de los Manantiales presta su ayuda a los enanos de Zar-Nabrand en su defensa del Barranco de Fuego. Se renuevan las relaciones comerciales con el reino enano.</p>},
            "149": {
                location: "Los Manantiales",
                zoom: 1,
                info: <p><span>E</span>l sulaid de los Manantiales recibe en audiencia a la líder nómada Filian Luna Roja de los tauros del Páramo Rojizo.</p>},
            "270": {
                location: [3716, 1633],
                zoom: 1,
                info: <p><span>H</span>ostigadas por las criaturas del Bosque Rojo, algunas familias abandonan sus hogares y peregrinan hacia la mítica espada de roca de las llanuras en busca de cobijo.</p>},
            "322": {
                location: "Korhen",
                zoom: 1,
                info: <p><span>C</span>onforme los humanos se asientan en las llanuras descubren restos de antiguas civilizaciones. Comienza la construcción de la ciudad sagrada de Korhen sobre las ruinas de una antigua urbe dedicada a la diosa Alea.</p>},
            "341": {
                location: [3377, 1373],
                zoom: 1,
                info: <p><span>L</span>os asentamientos druídicos del Bosque del Peregrino facilitan el paso de los viajeros. La región de Valdren empieza a poblarse con rapidez.</p>}, 
            "411": {
                location: "Drenan",
                zoom: 1,
                info: <p><span>L</span>os cazadores de osos del jarl Truon Korgarsson se asientan al norte del infame Bosque del Carnicero. A lo largo de los siguientes años la ciudad portuaria de Drenan crece rápidamente.</p>}, 
            "458": {
                location: [3373, 1692],
                zoom: 1,
                info: <p><span>D</span>orthion nace en el seno de los Halcones, una familia noble que habita los prados del Lerindrol.</p>},
            "467": {
                location: [3643, 1915],
                zoom: 1,
                info: <p><span>L</span>os clanes que gobiernan el Hogar declaran la guerra a los pielesverdes de las Cuevas cuando una vieja conocida, Rashka la Desolladora, es nombrada señora de la guerra.</p>}, 
            "474": {
                location: "Espada de Fasdar",
                zoom: 1,
                info: <p><span>A</span> la muerte de su madre, Dorthion hereda el liderazgo de los Halcones. El clan decide abandonar su hogar y dirigirse hacia el norte siguiendo la estela de otros clanes. La Espada de Fasdar recibe a los recién llegados que se apresuran a defender el enclave ante la llegada de los pielesverdes.</p>},
            "475": {
                location: "Espada de Fasdar",
                zoom: 1,
                info:<p><span>C</span>omienza la Segunda Gran Guerra.</p>,
                title: "Lazos de hermandad",
                text: <Fragment> <p><span>L</span>os Halcones de Dorthion y algunos otros clanes reunidos bajo la Espada de Fasdar son acosados por los orcos. </p> 
<p><span>E</span>l señor de clan, advertido por el dios Hurowk, sugiere envíar mensajeros a los enanos y una desesperada misiva a los elfos del oeste en el único barco que se ha podido construir al abrigo del Bosque Pardo.</p> 
<p><span>L</span>os enanos responden enviando un ejército bajo el mando del príncipe Kagrein de Darr-Kar.</p> </Fragment>},
            "480": {
                location: "Espada de Fasdar",
                zoom: 1,
                info:<p><span>D</span>orthion es nombrado guardián de la ciudad por el clan regente.</p>},
            "482": {
                location: "Drenan",
                zoom: 1,
                info:<p><span>V</span>agas noticias sobre asentamientos orcos arrasados de la noche a la mañana llegan a oídos de humanos y enanos.</p>},
            "510": {
                location: "Espada de Fasdar",
                zoom: 1,
                info:<p><span>N</span>ace Meldor, hijo de Dorthion.</p>},
            "513":  {
                location: "Lerindor",
                zoom: 1,
                info:<p><span>L</span>os señores de Lerindor contactan con una fuerza élfica llegada de Sarler. Un joven a quien llaman "el Búho" consigue remontar el Lerindrol y hace llegar las noticias al señor de clan Dorthion.</p>},
            "528": {
                location: [3373, 1692],
                zoom: 1,
                info:<p><span>M</span>uere Dorthion en combate contra la señora de la guerra pielverde Raksha. Su joven hijo, Meldor, venga su muerte.</p>},
            "529":  {
                location: [3189, 1571],
                zoom: 1,
                info:<p><span>T</span>iene lugar la firma del Pacto del Vado del Lerindrol entre humanos y enanos.</p>},
            "571": {
                location: "Espada de Fasdar",
                zoom: 1,
                info:<p><span>N</span>ace Kelirion, hijo de Meldor y Arien</p>},
            "574": {
                location: "Espada de Fasdar",
                zoom: 1,
                info:<p><span>N</span>acen los hermanos de Kelirion, Anaer y Soren tras un difícil parto.</p>},
            "583": {
                location: [3189, 1571],
                zoom: 1,
                info:<p><span>E</span>lfos, enanos y humanos consiguen una victoria decisiva en el valle del Lerindrol. Las fuerzas de Lerindor y Puertarrío rinden pleitesía al guardián de la Espada de Fasdar, el caballero Meldor.</p>},
            "585": {
                location: "Espada de Fasdar",
                zoom: 1,
                info:<p><span>M</span>eldor es coronado Rey de las Llanuras y Señor del Valle del Lerindrol.</p>},
            "586": {
                location: [3220, 1481],
                zoom: 1,
                info:<p><span>C</span>on la ayuda de elfos y enanos las grandes ciudades humanas crecen rápidamente mientras más familias se refugian tras sus murallas.</p>},
            "598": {
                location: "Espada de Fasdar",
                zoom: 1,
                info:<p><span>M</span>uere Meldor, considerado por muchos el primer gran rey humano de la Segunda Era. Su cuerpo es enterrado en una isla sobre el Lerindrol en la que se alza una enorme y antigua edificación, el Santuario de los Héroes.</p>},
            "599": {
                location: "Drenan",
                zoom: 1,
                info:<p><span>K</span>elirion hereda la corona de Meldor. Anaer es prometida al rey Klargan de Huraman pero su hermano Soren ataca los puertos de la ciudad de Drenan y acaba con la vida del monarca, coronándose a sí mismo como rey. Anaer permanece allí como embajadora y mediadora ante los jarls huramans, que pugnan por el trono y se niegan a reconocer al usurpador. Intentando ganarse el afecto de los jarls, Soren adopta un nombre huraman, Humar.</p>},
            "601": {
                location: [3143, 1216],
                zoom: 1,
                info:<p><span>A</span>provechando la debilidad del reino de Huraman, los orcos atacan Drenan en hordas. Los ataques se suceden durante años, manteniendo en constante alerta a los reinos humanos. El jarl Skal Grundsson dirige la defensa de las granjas y fortalezas sureñas de Huraman ante los ataques de pielesverdes y criaturas del Bosque del Carnicero. Muchas voces se alzan proclamándole rey de los huramans.</p>},
            "608": {
                location: [3107, 1436],
                zoom: 1,
                info:<p><span>L</span>as fuerzas del rey Humar se enfrentan a los jarls disidentes bajo el mando del jarl Skal Grundsson.</p>,
                title: "La Batalla de la linde del Carnicero",
                text: <Fragment><p><span>L</span>os jarls de Huraman se oponen abiertamente al usurpador Humar de Meldor y eligen su propio rey, el jarl Skal Grundsson. </p>
<p><span>I</span>ncapaz de tolerar semejante insulto, el rey Humar convoca a sus tropas leales y acosa los territorios disidentes haciendo estallar la guerra civil en el reino. El rey Skal conduce a sus tropas hasta la linde del Bosque del Carnicero, donde su veteranía puede concederle una cierta ventaja. </p>
<p><span>H</span>umar persigue a su rival y se produce el enfrentamiento final entre ambos bandos. Tras varias horas de combate ninguno consigue una clara victoria y unos y otros se repliegan. El desgaste de ambos ejércitos tras batallar contra los pielesverdes es evidente y finalmente los jarls se ven obligados a retirar sus pretensiones. </p> </Fragment>},
            "611": {
                location: "Espada de Fasdar",
                zoom: 1,
                info:<p><span>N</span>ace Dhiron, hijo de Kelirion y Suara, en la Espada de Fasdar.</p>},
            "620": {
                location: "Espada de Fasdar",
                zoom: 1,
                info:<p><span>N</span>ace Anaer, hijo de Kelirion y Suara, en la Espada de Fasdar.</p>},
            "628": {
                location: "Drenan",
                zoom: 1,
                info:<p><span>N</span>ace Valg, hijo de Humar y Sigur, en Drenan.</p>},
            "639": {
                location: "Korhen",
                zoom: 1,
                info:<p><span>L</span>a defensa de Korhen es organizada por dos fervientes sacerdotes de Alea.</p>,
                title: "La Defensa de Korhen",
                text: <Fragment><p><span>L</span>a ciudad sagrada de Korhen recibe un duro castigo durante las semanas que dura el súbito asedio pielverde. Esta imprevisible invasión sorprende a la ciudad en pleno invierno y sin apenas provisiones. </p>
                <p><span>L</span>os rezos de Marial y Augus invocan el poder de Alea como nunca antes habían visto los meldor. Llegados al momento final, las plegarias alzan una cúpula dorada que envuelve la ciudad, calcinando a los enemigos de Alea y sanando a sus fieles al mismo tiempo. </p> 
                </Fragment>},
            "640": {
                location: "Espada de Fasdar",
                zoom: 1,
                info:<p><span>T</span>ras una emboscada pielverde, el rey Kelirion es gravemente herido y recibe en sueños la visita del dios Hurowk, quien le advierte de la llegada de un gran mal desde el sur. Ese mismo día el rey envía mensajes a todos los pueblos de Maëron.</p>},
            "642": {
                location: "Espada de Fasdar",
                zoom: 1,
                info:<p><span>L</span>a Espada de Fasdar es defendida por Augus de Korhen y el comandante de los Hijos de Fasdar, Litner Sar. El príncipe Dhiron presta ayuda a su tío en Huraman y regresa acompañado de un ejército elfo y una poderosa hechicera humana, Tatja Truon de Drenan.</p>,
                title: "La última torre",
                text: <Fragment><p><span>A</span>ugus de Korhen y Litner Sar comandan la defensa de la capital del reino en ausencia del rey. </p> 
<p><span>C</span>uando este regresa, encuentra las murallas parcialmente derruidas pero la ciudad libre de pielesverdes, que permanecen en la llanura. Atrapados ahora entre dos frentes, los orcos se lanzan sobre las brechas de la muralla en un último ataque desesperado. </p> 
<p><span>E</span>l rey Dhiron, el comandante Litner, Augus de Korhen, el maestro de armas Noron Ginen y los Caballeros del Vado liderados por la mariscal Glorana Lerson encabezan la defensa mientras la emisaria Huramar Tatja Truon dirige un concilio de magos y hechiceros que diezma las fuerzas atacantes. Tan solo un torreón de la muralla exterior logra resistir, con Tatja Truon esgrimiendo poderes inconcebibles desde sus almenas. </p> </Fragment>},
            "648": {
                location: "Espada de Fasdar",
                zoom: 1,
                info:<p><span>L</span>a última gran invasión pielverde termina. Esta vez la inmensa mayoría de las tropas élficas son obligadas a regresar a su hogar. Dhiron contrae matrimonio con Tatja, que adopta el nombre meldor de Tatiana.</p>},
            "652": {
                location: "Espada de Fasdar",
                zoom: 1,
                info:<p><span>M</span>uere Kelirion en el palacio de Meldor. Dhiron es coronado por su madre en una sobria ceremonia.</p>},
            "653": {
                location: [3407, 2115],
                zoom: 0.85,
                info:<p><span>A</span>l sur de los Dientes de Sergan los orcos se preparan de nuevo para la guerra. Rumores del regreso de un mal antiguo se extienden por los reinos humanos.</p>},
        }
    }
];

export {completeTimeline, timeLineEventsByRace};