import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";

import Map from '../Map/Map';

import './main.scss';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
  
    render () {
        const { url } = this.props.match;
        return (
            <div className="main">
                <Router>
                    <main className="main_belly">
                        <Route path={`${url}/`} component={Map} />
                    </main>
                </Router>
            </div>
        );
    }
}

export default Main;