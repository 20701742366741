import React from 'react';

import DraggableMap from '../DraggableMap/DraggableMap';
import MapImage from '../../../assets/images/MaeronMapLarge.jpg';

import './mapDisplay.scss';

class MapDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zoom: 0.34,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.zoom &&  this.props.zoom !== this.state.zoom) {
        this.setState({ zoom: this.props.zoom });
        }
    }

    wheelZoom = (e) => {
        const { zoom } = this.state ;
        let newZoom = zoom;

        if (e.deltaY > 0) { newZoom = newZoom -= 0.03 }
        else { newZoom = newZoom += 0.03 };

        //zoom mínimo
        if (newZoom <= 0.3) {newZoom = 0.3};
        //zoom máximo
        if (newZoom >= 1) {newZoom = 1};
  
        this.setState({ zoom: newZoom });
    }

    render () {
        const { zoom } = this.state;
        const { center, onLoadMap, mapLoading, infoPanel, slowTransition } = this.props;

        return (
            <div className="mapDisplay" onWheel={(e)=> this.wheelZoom(e)} >
                <DraggableMap center={center} zoom={this.props.zoom || zoom} mapLoading={mapLoading} infoPanel={infoPanel} slowTransition={slowTransition}>
                    <div>
                        <img alt="mapa de Maëron" src={MapImage} onLoad={onLoadMap()}/>
                        {/* {this.buildLocations()} */}
                    </div>
                </DraggableMap>
            </div> 
        );
    }
}

export default MapDisplay;