const locationCoords= {
    //Fregir
    "Espada de Fasdar": {title:'Espada de Fasdar', coords: [3362, 1536]},
    "Korhen": {title:'Korhen', coords: [3441, 1518]},
    "Lerindor": {title:'Lerindor', coords: [3043, 1574]},
    "Nabar Amra": {title:'Nabar Amra', coords: [3792, 1419]},
    "Puertogaviota": {title:'Puertogaviota', coords: [3686, 2298]},
    "Drenan": {title:'Drenan', coords: [3132, 1322]},
    "Puertoballena": {title:'Puertoballena', coords: [3051, 1061]},
    "Casa Fuerte de Tronco Viejo": {title:'Casa Fuerte de Tronco Viejo', coords: [3467, 1880]},
    "Ramafiel": {title:'Ramafiel', coords: [3285, 1413]}, 
    "Los Manantiales": {title:'Los Manantiales', coords: [3323, 1225]},
    "Thalim": {title:'Thalim', coords: [3814, 2061]},
    "Har-Kar": {title:'Har-Kar', coords: [3452, 885]},
    "Darr-Kar": {title:'Darr-Kar', coords: [3453, 1156]},
    "Zar-Nabrand": {title:'Zar-Nabrand', coords: [3283, 1090]},
    "Loragog": {title:'Loragog', coords: [3246, 1170]},
    "Lothaniel": {title:'Lothaniel', coords: [4112, 1676]},
    "La Ciudadela Olvidada": {title:'La Ciudadela Olvidada', coords: [3087, 1976]},
    "Las Cuevas": {title:'Las Cuevas', coords: [3285, 2028]},
    "Garrazar": {title:'Garrazar', coords: [3379, 2233]},
    "Dong Jia": {title:'Dong Jia', coords: [3999, 1384]},
    //Sarler
    "Duanna": {title:'Duanna', coords: [1885, 873]},
    "Naerlan": {title:'Naerlan', coords: [1682, 1255]},
    "Alarion-Nasaril": {title:'Alarion-Nasaril', coords: [1887, 1312]},
    "Tamuldrol-Carsaen": {title:'Tamuldrol-Carsaen', coords: [1557, 1287]},
    "Thenariel": {title:'Thenariel', coords: [1808, 1181]},
    "Dor-Rheuin": {title:'Dor-Rheuin', coords: [1899, 1146]},
    "Tajamar": {title:'Tajamar', coords: [2023, 1709]},
    "Doran-Malanor": {title:'Doran-Malanor', coords: [1540, 1569]},
    "Doranhuthiennorel": {title:'Dranhuthiennorel', coords: [727, 1461]},
    "Nisanael Izalen": {title:'Nisanael Izalen', coords: [1092, 1344]},
    "Nisanael Azalen": {title:'Nisanael Azalen', coords: [1088, 1241]},
    "Dor-Alar": {title:'Dor-Alar', coords: [837, 1643]},
    "Dor-Neralk": {title:'Dor-Neralk', coords: [1284, 1574]},
    "Dor-Nimaril": {title:'Dor-Nimaril', coords: [1175, 1805]},
    "Pawak": {title:'Pawak', coords: [1095, 1645]},
    "Dor-Huthien": {title:'Dor-Huthien', coords: [1975, 2041]},
    "Dor-Felúr": {title:'Dor-Felúr', coords: [1921, 2191]},
    "Ralnnaer": {title:'Ralnnaer', coords: [1730, 2101]},
    "Val-Andur": {title:'Val-Andur', coords: [1249, 2106]},
    "El Pacto": {title:'E Pacto', coords: [1059, 2013]},
    "Bajopuente": {title:'Bajopuente', coords: [1141, 1953]},
    //Otros
    "Monte Pyros": {title:'Monte Pyros', coords: [2408, 3226]},
    "La Atalaya de Hielo": {title:'La Atalaya de Hielo', coords: [3220, 700]},
    "Vientohelado": {title:'Vientohelado', coords: [2306, 300]},
    "Torres de Hielo": {title:'Torres de Hielo', coords: [3808, 376]},
    "Thalagal": {title:'Thalagal', coords: [3279, 296]},
};

export default locationCoords;