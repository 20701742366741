import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Landing from './routes/Landing/Landing';
import Main from './routes/Main/Main';
import './App.scss';

class App extends React.Component {
    
  constructor(props) {
      super(props);
      const lang = localStorage && localStorage.getItem('maeron-lang');
      this.state = {
          multilang: false,
          lang: lang || 'es',
      };
  }

  
  setLanguage = (lang) => {
    localStorage.setItem('maeron-lang', lang);
    this.setState({
      lang: lang
    })
  }

  languageSwitcher = () => (
    <ul>
      <li onClick={()=> this.setLanguage('es')}>ES</li>
      <li onClick={()=> this.setLanguage('en')}>EN</li>
    </ul>
  );

  render () {
    const { lang, multilang } = this.state;

    return (
      <Router>
        {multilang && this.languageSwitcher}
        <Switch>
          <Route 
            exact path="/" 
            render={routerProps => (<Landing match={routerProps.match} lang={lang} />)}
          />
          <Route path="/:lang" component={Main} />
        </Switch>
      </Router>
    );
  }
}

export default App;
