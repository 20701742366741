import React from 'react';

// import Slider from '../../components/SliderInput/Sliderinput.js'

import spinner from '../../assets/images/spinner.png';

import './spinner.scss';

const Spinner = ({loading}) => {
    const style = loading? {} : {display: 'none'}
    return (
        <div className="spinner_wrapper" style={style}>
            <img className="spinner" src={spinner} alt="Loading..."/>
        </div>
    );
}

export default Spinner;