import React from 'react';
import PropTypes from 'prop-types';

import './about.scss';

const About = ({closeWindow}) => {

  onclick = (e) => {
    e.stopPropagation();
    closeWindow('aboutOpen');
  }
  return (
          <div className="background" onClick={(e) =>onclick(e)}>
            <div className="about">
              <aside  onClick={(e)=> e.stopPropagation() }>
                <h1>Bienvenidos a Maëron<span>®</span></h1>
                <p> Maëron es un lugar de fantasía capaz de albergar una infinidad de aventuras, un universo legendario repleto de historias pero sobre todo es un viejo sueño hecho realidad.
                </p>
                <p>
                Créditos:
                </p>
                <ul>
                  <li> Ambientación: <b>Jacinto García</b> (<a href='https://twitter.com/JacinMaeron' target='blank'>@JacinMaeron</a>).</li>
                  <li> Cartografía: <b>Marc Moureau</b> (<a href='https://twitter.com/marc_moureau' target='blank'>@marc_moureau</a>).</li>
                  <li> Edición de vídeo: <b>Bea González</b> (<a href='https://twitter.com/_superbea_' target='blank'>@_superbea_</a>).</li>
                  <li> Texturas papel: <b>Irene Zeleskou</b> (<a href='https://www.deviantart.com/ftourini' target='blank'>@ftourini</a>).</li>
                  <li> Desarrollo web: <b>Sandra Pinazo</b> (<a href='https://twitter.com/irisen_' target='blank'>@irisen_</a>).</li>
                </ul>
                <p className="bugfix">Para problemas técnicos y sugerencias ponte en contacto en este email: <b>help@maeron.net</b></p>
              </aside>
              <footer>®Maeron es una marca registrada por Jacinto Sergio García Aranda</footer>
            </div>
          </div>
  );
  
}

About.propTypes = {
  closeWindow: PropTypes.func.isRequired,
}

About.defaultProps = {
  content: '',
};

export default About;