import React from 'react';
import PropTypes from 'prop-types';

import './tutorial.scss';
import web from '../../assets/images/tutorial/web.png'


class Tutorial extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      selectedTuto: null,
    };
    this.timelinesSet = React.createRef();
  }

  onclick = (e) => {
    e.stopPropagation();
    this.props.closeWindow('tutoOpen');
  }

  onMouseOver = (type) => {
    this.setState({
      selectedTuto: type,
    });
  }

  onMouseOut = () => {
    this.setState({
      selectedTuto: null,
    });
  }

  mapTuto =  () =>
    <div> 
      <p>En esta zona se encuentra el <b>mapa</b> de Maëron.</p>
      <ul>
        <li> Haz <b>click y arrastra</b> para mover el mapa. </li>
        <li> Con la <b>rueda</b> del ratón puedes controlar el zoom del mapa.</li>
      </ul>
    </div>

  markerTuto =  () =>
    <div> 
      <p>Situando el ratón sobre el papel con sello y cinta se desplegará.</p>
      <ul>
        <li> <b>Sobre el proyecto</b>: Haciendo <b>click</b> aquí aparecerá una ventana con información sobre Maëron, créditos, etc.</li>
        <li> <b>Tutorial web</b>: Haz <b>click</b> ahí si quieres volver a ver este tutorial.</li>
      </ul>
      <p>Ambas ventanas se cierran haciendo click en el borde o fuera de ellas.</p>
    </div>

  timelineTuto =  () =>
    <div className='timelineTuto'> 
      <p>Esto es la <b>línea de tiempo</b> de la Segunda Era (S.E.) de Maëron. Mejor aún, las 3 líneas de tiempo según cada una de las razas principales del mundo.</p>
      <ul>
        <li> Puedes ocultar y mostrar cada una de las líneas temporales haciendo <b>click</b> en la pestaña de su raza. </li>
        <li> Con la <b>rueda</b> del ratón puedes desplazarte adelante o atrás para ver el resto de la línea de tiempo.</li>
        <li> Los rombos dorados representan eventos. Si haces <b>click</b> en un rombo aparecerá una breve descripción del evento y el mapa se desplazará al lugar donde se centra la acción.</li>
      </ul>
    </div>
  
  buildContent = () => {
    switch (this.state.selectedTuto) {
      case 'map':
        return this.mapTuto();
      case 'marker':
        return this.markerTuto();
      case 'timeline':
        return this.timelineTuto();
      default:
        return <p className='tutoDefault'>Pasa el ratón por encima de una de las zonas de la web para saber como utilizarla</p>
    }
  }

  render () {
    const content = this.buildContent();
    return (
            <div className="background" onClick={(e) => this.onclick(e)}>
              <div className="tutorial">
                <aside  onClick={(e)=> e.stopPropagation() }>
                  <h1>Cómo usar la web de Maëron<span>®</span></h1>
                  <div className="mapBox" onMouseOver={()=> this.onMouseOver('map')} onMouseOut={() => this.onMouseOut()}/>
                  <div className="markerBox" onMouseOver={()=> this.onMouseOver('marker')} onMouseOut={() => this.onMouseOut()}/>
                  <div className="timelineBox" onMouseOver={()=> this.onMouseOver('timeline')} onMouseOut={() => this.onMouseOut()}/>
                  <img src={web} alt="captura web Maëron"/>
                  
                  {content}
                </aside>
              </div>
            </div>
    );
  }
}

Tutorial.propTypes = {
  closeWindow: PropTypes.func.isRequired,
}

Tutorial.defaultProps = {
  content: '',
};

export default Tutorial;