import React, { Fragment } from 'react';

// components
import MapDisplay from '../../components/routeMap/MapDisplay/MapDisplay';
import InfoPanel from '../../components/InfoPanel/InfoPanel';
import Modal from '../../components/Modal/Modal';
import Markers from '../../components/Markers/Markers';
import Spinner from '../../components/Spinner/Spinner';
import TimeLine from '../../components/routeMap/TimeLine/TimeLine';
import TimeLineYears from '../../components/routeMap/TimeLineYears/TimeLineYears';
import About from '../../components/About/About';
import Tutorial from '../../components/Tutorial/Tutorial';
import Intro from '../../components/Intro/Intro';
import MobileWarning from '../../components/MobileWarning/MobileWarning';

// assets
import {timeLineEventsByRace} from '../../assets/data/timelineEvents';
import locationCoords from '../../assets/data/locationsCoords';

import './map.scss';

class Map extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        position: null,
        zoom: null,
        newZoom: null,
        infoOpen: false,
        panelInfo: {},
        mapLoading: true,
        modalOpen: false,
        selectedEvent: null,
        slowTransition: null,
        aboutOpen: false,
        tutoOpen: true,
        video: true,
        timelinesOpen: timeLineEventsByRace.reduce((acc, item) => {acc[item.race]=true; return acc}, {})
      };
      this.timelinesSet = React.createRef();
  }


  onClickTimelineEvent = (info, race, year) => {
    const coord = typeof info.location === 'string'? locationCoords[info.location].coords : info.location;
    this.setState({
      position: coord,
      zoom: info.zoom,
      infoOpen: false,
      panelInfo: info,
      modalOpen: true,
      selectedEvent: [race, year],
    });
  };

  onLoadMap = () => {
    this.setState({mapLoading: false});
  };

  clearSelectedEvent = () => {
    this.setState({selectedEvent: null})
  };

  closeInfo = (panel) => {
    this.setState({[panel]: false});
    setTimeout(this.clearSelectedEvent, 500);
  };

  onClickMoreInfo = (info) => {
    const newState = {
      infoOpen: true,
      modalOpen: false,
      panelInfo: info,
    };
  
    this.setState(newState);
  };

  buildInfo = () => {
    const {panelInfo, selectedEvent, infoOpen} = this.state;
    if (!infoOpen || !panelInfo || !selectedEvent) return;
    const date = () => {
     const location = typeof panelInfo?.location === 'string'? <span className='infoLocation'>{panelInfo?.location},</span> : '';
     return <small>{location} {`${selectedEvent[1]} S. E.`}</small>;
    };

    return( panelInfo &&
      <div className="map_info">
        {date()}
        {panelInfo.icon && <img className="map_info-img" src={panelInfo.icon[0]} alt={`${panelInfo.title}`}/>}
        <h1>{panelInfo?.title}</h1>
        {panelInfo?.text}
      </div>);
  };

  buildEventModal = () => {
    const {panelInfo, selectedEvent} = this.state;
    const footer = () => {
     const location = typeof panelInfo?.location === 'string'? <span>{panelInfo?.location},</span> : '';
     return <small>{location} {`${selectedEvent[1]} S. E.`}</small>;
    } 
    return( this.state.selectedEvent &&
      <div className="map_modal">
        {panelInfo?.info}
        {footer()}
      </div>);
  }

  wheelScroll = (e) => {
    if (e.deltaY > 0) {this.timelinesSet.current.scrollLeft += 100;}
    else {this.timelinesSet.current.scrollLeft -= 100};
  }

  onMouseOutTimeLine = () => {
    this.setState({
      zoom: null,
      newZoom: this.state.zoom,
      slowTransition: false,
      position: null,
    });
  }

  onMouseEnterTimeLine = () => {
    this.setState({
      zoom: null,
      newZoom: this.state.zoom,
      slowTransition: true,
    });
  }

  openWindow = (e,type) => {
    e.stopPropagation();
    this.setState({
          [type]: true,
        });
  }

  closeWindow = (type) => {
    this.setState({
          [type]: false,
        });
  }

  onVideoEnded = () => {
    this.setState({
          video: false,
        });
  }

  onClickRaceTab = (race, value) => {
    this.setState((prevState, props) => ({
      modalOpen: false,
      timelinesOpen:{ ...this.state.timelinesOpen, [race]: value }}));
  }

  render () {
    const { position, zoom, mapLoading, infoOpen, panelInfo, modalOpen, selectedEvent, slowTransition, aboutOpen, tutoOpen, video, timelinesOpen } = this.state;
    const timeline = timeLineEventsByRace.map((raceEvents, i )=> <TimeLine key={i} raceInfo={raceEvents} onClickEvent={this.onClickTimelineEvent} selectedEvent={selectedEvent} onMouseEnter={()=> this.onMouseEnterTimeLine()} onClickTab={this.onClickRaceTab}/>);
    const modalContent = this.buildEventModal();
    const infoContent = this.buildInfo();
    const isMobile= window.screen.width < 900;
    const yearsIsOpen = Object.values(timelinesOpen).includes(true);
    const footerStyle = yearsIsOpen? {} : {padding: 0};

    return (
      <Fragment>
        {isMobile
        ? <MobileWarning/>
        :<>
          <Spinner loading={mapLoading}/>
          <InfoPanel open={infoOpen} onClose={() => this.closeInfo("infoOpen")} content={infoContent}/>
          <section className="map" style={mapLoading ? {display: 'none'} : {}}>
              <MapDisplay center={position} zoom={zoom} newZoom={this.state.newzoom} onLoadMap={()=>this.onLoadMap} mapLoading={mapLoading} onClickLocation={this.onClickMoreInfo} infoPanel={infoOpen} slowTransition={slowTransition}/>
              <Modal isOpen={modalOpen} closeCallBack={() => this.closeInfo("modalOpen")} content={modalContent} hasButton={panelInfo?.title} moreInfoCallBack={()=>this.onClickMoreInfo(panelInfo)} timelinesOpen={timelinesOpen}/>
          </section>
          <Markers openWindow={this.openWindow}/>
          <footer className="map_footer" onWheel={(e)=> this.wheelScroll(e)} style={footerStyle}>
            <div className="map_footer__container" ref={this.timelinesSet}>
                <TimeLineYears open={yearsIsOpen} onMouseOut={()=> this.onMouseOutTimeLine()}/>
                {timeline}
            </div>
          </footer>
          {aboutOpen && <About closeWindow={this.closeWindow}/>}
          {tutoOpen && <Tutorial closeWindow={this.closeWindow}/>}
          {video && <Intro onVideoEnded={this.onVideoEnded}/>}
        </>}
      </Fragment>    
    );
  }
}

export default Map;