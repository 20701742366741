import React from 'react';
import PropTypes from 'prop-types';

import './modal.scss';

const Modal = ({ isOpen, closeCallBack, moreInfoCallBack, content, hasButton, timelinesOpen }) => {

  const numberLinesOpen = Object.values(timelinesOpen).reduce((acc, line) => line? ++acc : acc, 0);

  const bottomDistance = () => {
    switch (numberLinesOpen) {
      case 1:
        return '19px';
      case 2:
        return '39px';
      case 3:
        return '59px';
      default:
        return '-30px';
    }
  }


  const style = isOpen? {bottom: bottomDistance()} : {};
  const button = hasButton && <button className="modal_button-more-info" onClick={moreInfoCallBack}>saber más</button>;

  return (
          <section className="modal" style={style}>
            <button className="modal_button-close" onClick={closeCallBack}/>
            {content}
            {button}
          </section>
  );
  
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCallBack: PropTypes.func.isRequired,
  content: PropTypes.any,
  timelinesOpen: PropTypes.object.isRequired,
}

Modal.defaultProps = {
  content: '',
};

export default Modal;