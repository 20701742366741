import React from 'react';
import PropTypes from 'prop-types';

import './infoPanel.scss';

class InfoPanel extends React.Component {
  render () {
      const { open, onClose, content } = this.props;
      const style = open? {} : {left: '-400px'};

    return (
        <section className="info-panel" style={style}>
          <button className="info-panel_button-close" onClick={onClose}/>
          {content}
        </section>
    );
  }
}

InfoPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any,
}

InfoPanel.defaultProps = {
  children: '',
};

export default InfoPanel;