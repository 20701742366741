import React from 'react';
import PropTypes from 'prop-types';

import './timeLineYears.scss';

import {completeTimeline} from '../../../assets/data/timelineEvents';

class TimeLineYears extends React.Component {
    buildTimeLineYears = () => {
        const mainTimeline = completeTimeline.map((section, i) => <div className="timelineYears__section" key={section}>{i%3===0 &&`${section} S. E.`}</div>);
        const timeline = [<div key="init" style={{width: '100px',  flexShrink: '0'}}/>, ...mainTimeline, <div  key="end" style={{width: '100px',  flexShrink: '0'}}/>]
        return timeline
    }

    render () {
        const { open, onMouseOut } = this.props;
        const timeLine = this.buildTimeLineYears();
        return open && <div className="timelineYears__line-container" onMouseOut={onMouseOut}>{timeLine}</div>;
    }
}

TimeLineYears.propTypes = {
    open: PropTypes.bool.isRequired,
    onMouseOut: PropTypes.func.isRequired,
}

TimeLineYears.defaultProps = {
    open: true,
}

export default TimeLineYears;