import React from 'react';

import './mobileWarning.scss';

const MobileWarning = () => {

    return (
        <div className='mobileBackground' >
            <div>
                <p className='mobileText'>Esta web aún no está optimizada para móvil</p>
            </div>
        </div>
    );
}

export default MobileWarning;