import React from 'react';

import video from '../../assets/intro.mp4';
import bienve from '../../assets/images/bienvenidos_maeron.png'

import './intro.scss';


class Intro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonVisible: false,
      vanish: false,
    };
    this.video = React.createRef();
    this.input = React.createRef();
  };

  onClickLogo = () => {
    this.video.current.play();
    this.setState({
          buttonVisible: false,
        });
    this.input.current.focus();
  };

  onVideoEnd = () => {
    this.setState({
          vanish: true,
        });
    setTimeout(() => {this.props.onVideoEnded()}, 1000);
  };

  onVideoLoaded = () => {
    this.setState({
          buttonVisible: true,
        });
  };

  onKeyUp = (e) => {
    (e.key === 'Escape') && this.onVideoEnd();
  }

  render () {
    const {vanish, buttonVisible} = this.state;
    const style= vanish? {opacity:0} : {};
    return (
      <div className='introBackground' onClick={() => this.onClickLogo()} style={style} onKeyUp={(e)=> this.onKeyUp(e)}>
        <input type="text" onKeyUp={(e) => this.onKeyUp(e)} ref={this.input}/> 
        {buttonVisible && <img src={bienve} className="logo" alt="click logo Maeron" /> }
        <video className='introVideo' src={video} type="video/m4v" onEnded={()=> this.onVideoEnd()} ref={this.video}  onLoadedData={() => this.onVideoLoaded()}>
          Tu navegador no admite el elemento <code>video</code>.
        </video>
        <p>Para saltar el vídeo pulsa "esc" </p>
      </div>
    );
  }
}

export default Intro;