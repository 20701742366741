import React from 'react';
import PropTypes from 'prop-types';

import './timeLine.scss';

import {completeTimeline} from '../../../assets/data/timelineEvents';
import selectedDot from '../../../assets/images/dot2.png';

class TimeLine extends React.Component {
    constructor(props) {
        super(props);
        const {race, events, color, offset} = this.props.raceInfo;
        this.state = {
            race: race,
            events: events,
            color: color,
            offset: offset,
            open: true,
        };
    }

    buildTimeLine = () => {
        const { race, events, color } = this.state;
        const { selectedEvent, onMouseEnter } = this.props;
        const mainTimeline = completeTimeline.map(section => {
            const style = selectedEvent && (race === selectedEvent[0] && section === selectedEvent[1]? {backgroundImage: `url(${selectedDot})`} : {});
            const dot = events[section] && <div className="timeline__dot" key={`dot ${race} ${section}`} style={style} onClick={()=>{
                this.props.onClickEvent(events[section], race, section);
            }} onMouseEnter={onMouseEnter}/> ;
            return <div className="timeline__section" key={`${race} ${section}`} style={{backgroundColor: color}}>{dot}</div>
        });
        const timeline = [<div key={`${race} init`} style={{background: `linear-gradient(0.25turn, transparent, ${color})`, width: '100px',  flexShrink: '0'}}/>, ...mainTimeline, <div  key={`${race} end`} style={{background: `linear-gradient(0.25turn, ${color}, transparent)`, width: '100px',  flexShrink: '0'}}/>]
        return timeline
    }

    onClick = () => {
        this.props.onClickTab(this.state.race, !this.state.open);
        this.setState((prevState, props) => ({ open: !prevState.open }));
    }

    render () {
        const { race, open, color, offset } = this.state;
        const timeLine = this.buildTimeLine();
        const lineContainer = open && <div className="timeline__line-container">{timeLine}</div>;
        const style = open? {backgroundColor: color, left: offset} : {backgroundColor: "rgba(0, 0, 0, 0.5)", left: offset};
        return (
            <React.Fragment >
                <button className="timeline__button" onClick={this.onClick} style={style}>{race}</button>
                {lineContainer}
            </React.Fragment>
        );
    }
}

TimeLine.propTypes = {
    raceInfo: PropTypes.object.isRequired,
    onClickEvent: PropTypes.func.isRequired,
    onClickTab: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
}

export default TimeLine;