import './landing.scss'

import React from 'react';
import { Link } from "react-router-dom";

import bienvenidos from '../../assets/images/bienvenidos_maeron.png';
        
        
function Landing ({lang}) {

    const title = lang === 'es'? <img src={bienvenidos} className="landing-title" alt="logo" /> : <h1>Welcome to Maeron</h1>
    const path = `/${lang}`;

    return (
        <Link to={path}>
          <div className="landing">
            <header className="landing-header">
              {title}
            </header>
          </div>  
        </Link>
    );
}   
        
        
export default Landing; 