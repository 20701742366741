import React from 'react';

import './draggableMap.scss';

class Draggable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialPosition: null, // Set de initial position (ej [3362, 1536]), if not set centers te map on loading
            mapLoading: true,
            imgWidth: 4950,
            imgHeight: 3510,
            currentPosition: [],
            currentCenter: this.props.center || [2475, 1755],
            initMouseX: 0,
            initMouseY: 0,
            dragging: false,
        };
        this.windowEl = React.createRef();
        this.mapImg = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        const {center, zoom, mapLoading, infoPanel} = this.props;

        const checkSameCoords = (first, second) => {
            if(!first) return;
            const sameX = first[1] === second[1];
            const sameY = first[2] === second[2];
            return sameX && sameY;
        }

        if (mapLoading !== prevProps.mapLoading) {
            const { imgWidth,
                 imgHeight,
                 initialPosition
                } = this.state;
            
            this.mapImg.current.onmousedown = this.mouseDown;

            const posX = initialPosition? initialPosition[0]-this.windowEl.current.offsetWidth/2 : imgWidth/2-this.windowEl.current.offsetWidth/2;
            const posY = initialPosition? initialPosition[1]-this.windowEl.current.offsetHeight/2 : imgHeight/2-this.windowEl.current.offsetHeight/2;

            this.setState({
                currentPosition: [-posX, -posY],
                currentCenter: initialPosition || [imgWidth/2, imgHeight/2]
            });
        }
        if ((zoom !== prevProps.zoom)||(infoPanel !== prevProps.infoPanel) ) {
            this.setState({
                dragging: true,
            });
        }
        if (center && !checkSameCoords(center, this.state.currentCenter)) {

            const posX = center[0]-this.windowEl.current.offsetWidth/2;
            const posY = center[1]-this.windowEl.current.offsetHeight/2;
            
            this.setState({
                currentPosition: [-posX, -posY],
                currentCenter: [center[0], center[1]],
                dragging: false,
            });
        };
    }

    mouseDown = (event) => {
        event = event || window.event;
        event.preventDefault();
        this.setState({
            initMouseX: event.clientX,
            initMouseY: event.clientY,
            dragging: true,
        });
        window.onmouseup = this.mouseUp;
        window.onmousemove = this.dagElement;
    }

    dagElement = (event) => {
        const { zoom } = this.props;
        const { initMouseX, initMouseY } = this.state;
        event = event || window.event;
        event.preventDefault();
        // calculate the new cursor position:
        const diferenceX = (initMouseX - event.clientX)/zoom;
        const diferenceY = (initMouseY - event.clientY)/zoom;
        this.setState({
            initMouseX: event.clientX,
            initMouseY: event.clientY,
        });

        this.setState(prevState=>{
            return({
            currentPosition: [this.mapImg.current.offsetLeft - diferenceX, this.mapImg.current.offsetTop - diferenceY],
            currentCenter: [prevState.currentCenter[0] + diferenceX, prevState.currentCenter[1]+ diferenceY],
        })});
    }

    mouseUp = () => {
        window.onmouseup = null;
        window.onmousemove = null;
    }

    render () {
        const { children, zoom, slowTransition } = this.props;
        const { currentPosition, currentCenter, dragging } = this.state;
        
        const newProps = {
            ref:this.mapImg,
            style:{ ...children.props.style, 
                position: 'absolute',
                top: `${currentPosition[1]}px`,
                left: `${currentPosition[0]}px`,
                transformOrigin: `${currentCenter[0]}px ${currentCenter[1]}px`,
                transform: `scale(${zoom})`,
            }
        };

        if(slowTransition || !dragging){newProps.style.transition = '1.5s ease-in-out'}
    
        return (
            <div className="draggableMap__window" ref={this.windowEl}>     
                {React.cloneElement(children, {...newProps})}
            </div>
        );
    }
}

export default Draggable;